.dashboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns for the first row */
  grid-template-rows: auto auto auto; /* Three rows for the dashboard */
  gap: 20px;
  padding: 20px;
  background-color: #f5f7fa;
  margin-left: 18%;
  height: 100%;
  align-items: center;
}

/* Shared Section Styling */
.section {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 calc(33.333% - 20px);
  display: flex;
  align-items: center;
  gap: 15px;
  transition: transform 0.2s ease-in-out;
  justify-content: center; /* Center content within each section */
  text-align: center; /* Center text in the section */
}

.section:hover {
  transform: translateY(-5px);
}

/* Icon and Text Layout */
.iconContainer {
  width: 13%;
  height: 13%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.textContainer {
  display: flex;
  flex-direction: column;
} 

/* Heading and Info Text */
.textContainer h3 {
  font-size: 25px;
  margin: 0;
  color: #333;
}

.roomInfo, .count {
  font-size: 18px;
  color: #777;
}

.roomInfo span, .count {
  font-size: 32px;
  font-weight: bold;
  color: #4CAF50; /* Accent color */
}

.header {
  margin-left: -10px;
  width: 102%;
  height: 80px;
  margin-top: -20px;
  grid-column: 1 / -1; /* Header spans all columns */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.greeting h1 {
  margin: 0;
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
}

.greeting p {
  margin: 0;
  font-size: 1em;
  color: #666;
}

.headerActions {
  display: flex;
  align-items: center;
}

.headerIcon,
.profileIcon {
  color: #666;
  margin-right: 15px;
  cursor: pointer;
  font-size: 1.8em;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userName {
  font-weight: 500;
  font-style: italic;
  font-size: 25px;
  margin-right: 20px;
  color: #333;
}

/* First Row: Outpatient Department, Total Appointments, Doctors */
.outpatientDepartment {
  grid-column: 1 / 2;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.totalAppointments {
  grid-column: 2 / 3;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.doctors {
  grid-column: 3 / 4;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Second Row: Patient Attended, Notifications */
.patientAttended {
  grid-column: 1 / 3; /* Spanning two columns */
  background-color: #ffffff;
  padding: 20px;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.patientAttended2 {
  grid-column: 1/4; /* Spanning two columns */
  background-color: #ffffff;
  padding: 20px;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.patientGraph{
  justify-self: center;
  height: 90%;
  width: 50%;
}

.notifications {
  grid-column: 3 / 4;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Third Row: Tables for Today's Appointment and Queue */
.tableRow {
  grid-column: 1 / -1; /* This will span the full width of the third row */
  
}

.tableContainer {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin-right: 20px;
}

.tableContainer:last-child {
  margin-right: 0; /* Remove margin for the last item */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tbody tr:hover {
  background-color: #ddd;
}

.seeMoreBtn{
  padding: 8px 16px;
  margin-top: 1%;
  border: none;
  background-color: #3f9f6d;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.seeMoreBtn:hover{
  background-color: #218838;
}

/* Responsive Layout */
@media (max-width: 1200x) {
  .dashboard {
    grid-template-columns: 1fr; /* Stack all columns on smaller screens */
  }

  .tableRow {
    flex-direction: column;
  }

  .tableContainer {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/* dashboardSecre.module.css */

.iconContainer {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.icon {
  font-size: 30px;
  color: #4CAF50;
}

.totalAppointments, .doctors {
  display: flex;
  align-items: center;
}


.notifications ul {
  list-style: none;
  padding: 0;
}

.notifications li {
  position: relative;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.notifications li:hover span {
  display: inline; 
}

.notifications li span {
  display: none;
}

.navigationButton {
  padding: 8px 16px;
  border: none;
  background-color: #3f9f6d;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.navigationButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


.chartBtn{
  padding: 8px 14px;
  margin-top: 1%;
  margin-right: 5px;
  border: none;
  background-color: #3f9f6d;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.chartBtn:hover{
  background-color: #218838;
}