/* General Layout */
.doctorsSection {
    padding: 20px;
    background-color: #f9fafb;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 18%;
    height: 900px;
}

/* Doctors Header */
.doctorsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doctorsHeader h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bolder;
}

.doctorsHeader p {
    color: gray;
    font-size: 14px;
}

.addDoctorBtn {
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
}

.addDoctorBtn:hover {
    background-color: #218838;
}

/* Search and Filter Section */
.searchAppointment {
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.searchAppointment input {
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
}

.filterBtn {
    padding: 11px 13px;
    background-color: white;
    /* Button background color */
    margin-left: 10px;
    border: none;
    /* Remove default button border */
    border-radius: 8px;
    /* Rounded edges */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Light shadow for 3D effect */
    cursor: pointer;
    /* Pointer on hover */
}

/* Table Header */
.tableHeader {
    margin-top: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 15px;
    border-bottom: 2px solid #eaeaea;
    font-weight: bold;
    color: black;
    font-size: 16px;
    text-align: left;
}

.tableHeader span {
    flex: 1;
    text-align: center;
}


/* Action Button */
.actionBtn {
    padding: 12px 40px;
    background-color: #313B34;
    /* Button background color */
    color: white;
    border: none;
    /* Remove default button border */
    border-radius: 8px;
    /* Rounded edges */
    cursor: pointer;
    /* Pointer on hover */
}

.actionBtn:hover {
    background-color: #49564d;
}

/* Header CSS */
.header {
    margin-left: -10px;
    width: 102%;
    height: 80px;
    margin-top: -20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.greeting {
    display: flex;
    flex-direction: column;
}

.greeting h1 {
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: #000;
}

.greeting p {
    margin: 0;
    font-size: 0.85em;
    color: #666;
}

.headerActions {
    display: flex;
    align-items: center;
}


.searchIcon {
    color: #666;
    margin-right: 15px;
    cursor: pointer;
    font-size: 1.5em;
}

.headerIcon,
.profileIcon {
    color: #666;
    margin-right: 15px;
    cursor: pointer;
    font-size: 1.5em;
}

.userInfo {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: #333;
}

.userName {
    font-weight: 500;
    font-style: italic;
    font-size: 25px;
    margin-right: 20px;
}

.iconButton {
    padding: 7px 10px;
    background-color: #313B34;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}


/* Modal Styling */
.modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    margin: auto;
    position: relative;
    color: #000;
}

.modalOverlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Form Groups */
.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.formGroup label {
    font-size: 14px;
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
}

.formGroup input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

/* 2 buttons CSS */
.modalActions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3%;
}

.backButton,
.editButton {
    padding: 14px 45px;
    margin-top: 2%;
    border-radius: 8px;
    cursor: pointer;
}

.backButton {
    background-color: white;
    color: black;
    border-style: solid;
    border-color: gray;
}

.editButton {
    background-color: gray;
    color: white;
    border-style: solid;
    border-color: black;
    margin-left: 10px;
}

.backButton:hover,
.editButton:hover {
    opacity: 0.8;
}

.container {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
}

.containerContent {
    color: #333;
    font-size: 14px;
    line-height: 1.5;
}