/* General Layout */
.doctorsSection {
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 18%;
  height: 100%;
}

/* Doctors Header */
.doctorsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doctorsHeader h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bolder;
}

.doctorsHeader p {
  color: gray;
  font-size: 14px;
  margin-top: 0;
}

.addDoctorBtn {
  padding: 16px 32px;
  background-color: #28a745;
  color: rgb(239, 239, 239);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.addDoctorBtn:hover {
  background-color: #218838;
  color: white;
}

/* Message Container */
.messageContainer {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 679px;
}

/* Sidebar */
.messageSidebar {
  width: 30%;
  background-color: #f1f3f9;
  border-right: 1px solid #e0e0e0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.sidebarHeader {
  margin-bottom: 10px;
  display: flex;
}

.sidebarSearch {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.srchBtn{
  padding: 11px 13px;
  background-color: white; /* Button background color */
  border: none; /* Remove default button border */
  border-radius: 8px; /* Rounded edges */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow for 3D effect */
  cursor: pointer; /* Pointer on hover */
  margin-left: 10px;
}

.messageItem {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 70px;
  border-radius: 4px;
  background-color: #EBEFF9;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* For Message Time in Sidebar */
.messageSidebar .messageTime {
  font-size: 14px;
  color: gray; /* Sidebar time color set to gray */
}

/* For Message Time in Message Body */
.messageBody .messageTime {
  font-size: 10px;
  color: black; /* Message body time color set to white */
  text-align: right;
  margin-top: 5px;
}


.messageBubble {
  padding: 10px;
  border-radius: 8px;
  margin: 5px 0;
  max-width: 60%;
  word-wrap: break-word;
}


.messageUser {
  display: flex;
  align-items: center;
  gap: 5px;
}

.userAvatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}


.userDetails h4 {
  margin: 0;
  font-size: 20px;
}

.userDetails p {
  margin: 0 2px;
  color: gray;
  font-size: 14px;
}

/* Chatbox */
.messagePanel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.messageHeader {
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.userAvatarLarge {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.messageBody {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Ensure scrolling when messages exceed the height */
}

.messageInput {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.inputBox {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sendButton {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}


/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.searchInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.patientList {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.patientItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.messageButton {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.messageButton:hover {
  background-color: #0056b3;
}

.closeButton {
  background-color: #ccc;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
}

.sentMessage {
  background-color: #d1e7dd; /* Light green for sent messages */
  align-self: flex-end; /* Align to the right */
  text-align: left;
  margin-left: auto; /* Push message to the right */
  padding: 10px;
  border-radius: 12px 12px 0 12px; /* Rounded corners */
  width:fit-content;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.receivedMessage {
  background-color: #ffffff; /* White for received messages */
  border: 1px solid #ccc; /* Light border for received messages */
  align-self: flex-start; /* Align to the left */
  text-align: left;
  margin-right: auto; /* Push message to the left */
  padding: 10px;
  border-radius: 12px 12px 12px 0; /* Rounded corners */
  width:fit-content;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}
