/* General Layout */
.doctorsSection {
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 18%;
  height: 100%;
}


/* Doctors Header */
.doctorsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doctorsHeader h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bolder;
}

.doctorsHeader p {
  color: gray;
  font-size: 14px;
  margin-top: 0;
}

.addDoctorBtn {
  padding: 16px 43px;
  background-color: #28a745;
  color: rgb(239, 239, 239);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.addDoctorBtn:hover {
  background-color: #218838;
}

/* Search and Filter Section */
.searchAppointment {
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.searchAppointment input {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
}

.actionButtons {
  display: flex;
  gap: 1%;
}

.filterBtn {
  padding: 11px 13px;
  background-color: white;
  margin-left: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}


/* Table Header */
.tableHeader {
  margin-top: 1.5%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 15px;
  border-bottom: 2px solid #eaeaea;
  font-weight: bold;
  color: black;
  font-size: 16px;
  text-align: left;
}

.tableHeader span {
  flex: 1;
  text-align: center;
}

/* Table Row */
.tableRow {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #757373;
  font-weight: 600;
  text-align: center;
  background-color: #ffffff;
}

.tableRow:nth-child(odd) {
  background-color: #f7f7f7;
}

.tableRow:nth-child(even) {
  background-color: #ffffff;
}

.tableRow:hover {
  background-color: #f0f8ff;
}

.tableRow span {
  flex: 1;
  text-align: center;
}

/* Icon Button */
.iconButton {
  padding: 7px 10px;
  background-color: #313B34;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Modal Styling */
.modal {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  margin: auto;
  position: relative;
  color: #000;
}

.modalOverlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backButton,
.editButton {
  padding: 14px 45px;
  margin-top: 2%;
  border-radius: 8px;
  cursor: pointer;
}

.backButton {
  background-color: white;
  color: black;
  border-style: solid;
  border-color: gray;
}

.editButton {
  background-color: #313834;
  color: white;
  border-style: solid;
  border-color: black;
  margin-left: 10px;
}

.backButton:hover,
.editButton:hover {
  opacity: 0.8;
}

/* Add Doctor Modal */
.addDoctorModal {
  width: 600px;
  max-height: 80vh;
  margin: auto;
  background: white;
  padding: 30px;
  border-radius: 10px;
  overflow-y: auto;
  position: relative;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.formsection{
  gap: 10px;
}

/* Form Groups */
.formGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 2%;
}

.formGroup label {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.formGroup input, .formGroup select {
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* Inputs and Selects */
.inputField,
.selectField {
  width: 100%;
  padding: 10px;  
  margin: 10px 0;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.inlineFields {
  display: flex;
  justify-content: space-between;
}

.inlineFields .selectField {
  width: 48%;
}

/* Schedule Row */
.scheduleRow {
  margin-bottom: 15px;
}

.addScheduleBtn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* No border */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Inline-block for layout flexibility */
  font-size: 16px; /* Font size */
  margin: 10px 0; /* Margin for spacing */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners for a modern look */
  transition: background-color 0.3s ease; /* Smooth transition for background change */
}

.addScheduleBtn:hover {
  background-color: #45a049; /* Darker green on hover */
}

.addScheduleBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.removeScheduleBtn {
  background-color: #f07e7e; /* Light red background */
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px; /* Adds space between the buttons */
}

.removeScheduleBtn:disabled {
  background-color: #e0e0e0; /* Gray background for disabled state */
  border-color: #a0a0a0;
  cursor: not-allowed;
}

/* Action Buttons */
.modalActions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}

.addButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.actBtn{
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;
}

.cancelBtn, .submitBtn {
  margin-top: 10%;
  padding: 14px 45px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.cancelBtn {
  background-color: gray;
  color: white;
}

.cancelBtn:hover{
  background-color: #999999;
}

.submitBtn {
  background-color: green;
  color: white;
}

.submitBtn:hover{
  background-color: #339933;
}

.addButton {
  background-color: #4CAF50;
  color: white;
}

/* Readonly and Editable Input */
.readOnlyInput {
  background-color: #f0f0f0;
  cursor: not-allowed;
  width: 100%;
}

.editableInput {
  background-color: #ffffff;
  border: 1px solid #007bff;
  width: 100%;
}

.confirmModalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.warningIcon {
  font-size: 30px;
  color: red;
  margin-bottom: 10px;
}

.confirmActions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.cancelButton, .deleteButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.cancelButton {
  background: #f0f0f0;
  color: #333;
}

.deleteButton {
  background: #ff4d4d;
  color: white;
}

.selectField {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 5;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}



/* Responsive Design */
@media (max-width: 768px) {
  .modal {
    width: 90%;
    max-height: 90vh;
    padding: 15px;
  }

  .formGroup input, .formGroup select {
    padding: 8px;
    font-size: 13px;
  }

  .backButton,
  .editButton {
    font-size: 12px;
    padding: 8px 15px;
  }
}



/* data grid table design */
.dataGrid {
  border: none;
  font-family: Arial, sans-serif;
}

.dataGrid .MuiDataGrid-columnHeaders {
  background-color: #f5f5f5;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
}

.dataGrid .MuiDataGrid-columnHeaderTitle {
  font-size: 15px;
}

.dataGrid .MuiDataGrid-cell {
  border-bottom: none;
  padding: 8px;
  font-size: 14px;
}

.dataGrid .MuiDataGrid-row {
  background-color: #ffffff;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.dataGrid .MuiDataGrid-row:hover {
  background-color: #f0f0f0;
}

.iconButton {
  background-color: #4a4a4a;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 8px 10px;
  line-height: normal;
  font-size: 14px;
  text-transform: none;
  cursor: pointer;
}

.iconButton:hover {
  background-color: #333333;
}


.restoreBtn{
  padding: 0px 15px;
    border: none;
    background-color: #3f9f6d;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.restoreBtn:hover{
  background-color: #218838;
}