/* General Layout */
.doctorsSection {
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 18%;
  height: 100%;
}

/* Doctors Header */
.doctorsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doctorsHeader h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bolder;
}

.doctorsHeader p {
  color: gray;
  font-size: 14px;
}


/* Search and Filter Section */
.searchAppointment {
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.searchAppointment input {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
}

.filterBtn {
  padding: 11px 13px;
  background-color: white; /* Button background color */
  margin-left: 10px;
  border: none; /* Remove default button border */
  border-radius: 8px; /* Rounded edges */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow for 3D effect */
  cursor: pointer; /* Pointer on hover */
}

.iconButton{
  padding: 7px 10px;
  background-color: #313B34;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}


/* Modal Styling */
.archiveModal{
  height: max-content;
}

.modal {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  margin: auto;
  position: relative;
  color: #000;
}

.modalOverlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Form Groups */
.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.formGroup label {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.formGroup input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* 2 buttons CSS */
.modalActions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}

.backButton,
.editButton {
  padding: 14px 45px;
  margin-top: 2%;
  border-radius: 8px;
  cursor: pointer;
}

.backButton {
  background-color: white;
  color: black;
  border-style: solid;
  border-color: gray;
}

.editButton {
  background-color: #333;
  color: white;
  border-style: solid;
  border-color: black;
  margin-left: 10px;
}

.backButton:hover,
.editButton:hover {
  opacity: 0.8;
}


/* data grid table design */
.dataGrid {
  border: none;
  font-family: Arial, sans-serif;
}

.dataGrid .MuiDataGrid-columnHeaders {
  background-color: #f5f5f5;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
}

.dataGrid .MuiDataGrid-columnHeaderTitle {
  font-size: 15px;
}

.dataGrid .MuiDataGrid-cell {
  border-bottom: none;
  padding: 8px;
  font-size: 14px;
}

.dataGrid .MuiDataGrid-row {
  background-color: #ffffff;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.dataGrid .MuiDataGrid-row:hover {
  background-color: #f0f0f0;
}

.iconButton {
  background-color: #4a4a4a;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 8px 10px;
  line-height: normal;
  font-size: 14px;
  text-transform: none;
  cursor: pointer;
}

.iconButton:hover {
  background-color: #333333;
}
