/* Sidebar container styling */
.bgcol{
  background-color: #f9fafb;
}

body{
  overflow-y: scroll;
}

.sidebar1 {
  height: 100%;
  background-color: #313b34; /* Dark background similar to the image */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 250px; /* Set a fixed width for the sidebar */
  border-right: 2px solid #ddd; /* Light gray border */
  width: 18%;
}

/* Logo styling */
.logo1 {
  margin: 0 80px;
  margin-top: 30px;
}

.logo1 img {
  width: 150px; /* Adjusted logo size */
}

/* Navigation styling */
.nav1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.navItem1 {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  text-decoration: none;
  color: #b0bec5; /* Light text color */
  transition: background-color 0.3s, color 0.3s, padding-left 0.3s;
  border-radius: 5%;
  position: relative;
}


.navItem1 svg {
  font-size: 30px;
  margin-top: 3px;
  color: #b0bec5; /* Light icon color */
}

.navItem1 span {
  margin-left: 15px;
  font-size: 25px;
  font-weight: bold;
}

.navItem1:hover {
  background-color: #353d38; 
  color: #0ac11c; /* White text on hover */
  padding-left: 40px;
}

.aactive1 {
  background-color: #353d38;
  color: #0ac11c; 
  padding-left: 40px;
}

/* Additional padding at the bottom to prevent last item from being too close to the edge */
.sidebarBottomPadding {
  padding-bottom: 20px;
}

.logout {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  text-decoration: none;
  color: #b0bec5; /* Light text color */
  transition: background-color 0.3s, color 0.3s, padding-left 0.3s;
  border-radius: 5%;
  position: relative;
  margin-top: 10%;
}

.logout svg {
  font-size: 30px;
  margin-top: 3px;
  color: #b0bec5; /* Light icon color */
}

.logout span {
  margin-left: 15px;
  font-size: 25px;
  font-weight: bold;
}

.logout:hover {
  background-color: #353d38; 
  color: #0ac11c; /* White text on hover */
  padding-left: 40px;
}


/*header css*/
.header {
  height: 80px;
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-left: 19%;

}

.greeting h1 {
  margin: 0;
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
}

.greeting p {
  margin: 0;
  font-size: 1em;
  color: #333;
  margin-left: 7px;
}

.headerActions {
  position: relative;
  display: flex;
  align-items: center;
}

.headerIcon,
.profileIcon {
  color: #666;
  cursor: pointer;
  font-size: 1.8em;
}

.userName {
  font-weight: 500;
  font-size: 25px;
  margin-right: 20px;
  color: #333;
}


.user{
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 30px;
}

  /* Adjusting the notification container styling */
/* Dropdown styles */
.notificationDropdown {
  position: absolute;
  top: 90%; /* Position dropdown just below the notification icon */
  right: 78%; /* Align to the right */
  width: 320px; /* Increase width for better readability */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  max-height: 400px; /* Limit height with a scroll option */
  overflow-y: auto; /* Enable scrolling if notifications exceed height */
}

/* Individual notification item styling */
.notificationItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px; /* Increase padding for better spacing */
  border-radius: 6px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
}

.notificationItem:last-child {
  margin-bottom: 0;
}

/* Notification item hover effect */
.notificationItem:hover {
  background-color: #f5f5f5;
}

/* Text styling for notification message */
.notificationItem p {
  margin: 0;
  font-size: 0.95em;
  color: #333;
  line-height: 1.4;
}

/* Styling for small, timestamp text */
.notificationItem small {
  font-size: 0.8em;
  color: #888;
  margin-top: 4px;
}

.dropdownMenu {
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdownMenu[style*="display: block"] {
  opacity: 1;
  transform: translateY(0);
}