/* General Layout */
.doctorsSection {
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 18%;
  height: 100%;
}

/* Doctors Header */
.doctorsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doctorDetailsContainer {
  background-color: #f9fafb;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 45%;
  border: 2px solid #ccc;
}

.doctorDetailsContainer h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 3%;
}

.doctorDetailsContainer p {
  color: gray;
  font-size: 14px;
  margin-top: -1.5%;
}

/* Search and Filter Section */
.searchAppointment {
  margin-top: 1%;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.searchAppointment input {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
}

.addDoctorBtn {
  padding: 16px 41px;
  background-color: #28a745;
  color: rgb(239, 239, 239);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.addDoctorBtn:hover {
  color: rgb(239, 239, 239);
  background-color: #218838;
}




.header {
  margin-left: -10px;
  width: 102%;
  height: 80px;
  margin-top: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.greeting {
  display: flex;
  flex-direction: column;
}

.greeting h1 {
  margin: 0;
  font-size: 1.4em;
  font-weight: bold;
  color: #000;
}

.greeting p {
  margin: 0;
  font-size: 0.85em;
  color: #666;
}

.headerActions {
  display: flex;
  align-items: center;
}

.searchIcon {
  color: #666;
  margin-right: 15px;
  cursor: pointer;
  font-size: 1.5em;
}

.headerIcon,
.profileIcon {
  color: #666;
  margin-right: 15px;
  cursor: pointer;
  font-size: 1.5em;
}

.userInfo {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #333;
}

.userName {
  font-weight: 500;
  font-style: italic;
  font-size: 25px;
  margin-right: 20px;
}


/* Action Dropdown */
.actionDropdown {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  /* Ensure it fits the action cell */
}

.actionDropdown:focus {
  outline: none;
  border-color: #218838;
  /* Optional: highlight on focus */
}


/* Dropdown button styling */
.dropdownWrapper {
  position: relative;
  display: inline-block;
}

.dropdownToggle {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  width: 150px;
  z-index: 10;
}

.dropdownItem {
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}


/* General Table Styles */
.table {
  margin-top: 30px;
  width: 100%;
  border-collapse: collapse;
  /* Ensure no gaps between cells */
}

/* Table Header */
.tableHeader {
  background-color: #f0f0f0;
  border-bottom: 2px solid #eaeaea;
  font-weight: bold;
  color: black;
}

.tableHeader th {
  padding: 15px;
  /* Add padding for spacing */
  text-align: center;
  /* Center-align header text */
}

/* Table Body */
.tableBody {
  background-color: #ffffff;
  cursor: pointer;
  /* White background for rows */
}

/* Table Row */
.tableRow {
  transition: background-color 0.3s ease;
  /* Smooth background change on hover */
}

.tableRow td {
  padding: 12px;
  /* Add padding for cell content */
  text-align: center;
  /* Center-align cell text */
}

/* Zebra Striping */
.tableRow:nth-child(even) {
  background-color: #f7f7f7;
  /* Light gray for even rows */
}

.tableRow:hover {
  background-color: #f0f8ff;
  /* Highlight on hover */
}

/* Disabled Row */
.disabledRow {
  background-color: #f0f0f0;
  /* Light gray background */
  color: #a0a0a0;
  /* Gray text */
  pointer-events: none;
  /* Disable interactions */
}

/* No Data Row */
.noDataRow {
  text-align: center;
  padding: 16px;
  color: #555;
}


/* Dropdown button styling */
.dropdownWrapper {
  position: relative;
  display: inline-block;
}

.dropdownToggle {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  width: 150px;
  z-index: 10;
}

.dropdownItem {
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.disabledRow {
  background-color: #f0f0f0;
  /* Light gray background */
  color: #a0a0a0;
  /* Gray text */
  pointer-events: none;
  /* Disable interactions */
}


.currentQueueContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 23vh;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.queueInfo {
  text-align: center;
  /* Center text inside this div */
  justify-content: center;
}

.queueLabel {
  font-size: 24px;
  /* Adjust size as needed */
  font-weight: bold;
  /* Make label stand out */
  margin-bottom: 10px;
  /* Space below label */
}

.queueNumber {
  display: flex;
  /* Change to flex for centering */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  font-size: 50px;
  /* Large font for visibility */
  font-weight: 600;
  /* Bolder text */
  color: #007bff;
  /* Highlight color for queue number */
  min-height: 100px;
  /* Ensure enough height for centering */
}

.noQueueMessage {
  font-size: 20px;
  /* Adjust size for no queue message */
  color: #ff0000;
  /* Red color to indicate no queue */
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center QR code horizontally */
  justify-content: center;
  /* Center QR code vertically if necessary */
  position: relative;
}

.closeModalButton{
  position: fixed; /* Set to fixed for positioning relative to modal */
  top: 27%;
  right: 22%;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 30px;
  z-index: 1000; /* Ensure it stays above other content */
}

.openQRCodeButton {
  padding: 10px 20px;
  background-color: #2c3e50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 15px;
}

.buttonContainer {
  display: flex;
  gap: 10px;
}

.centeredError {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensures full viewport height for vertical centering */
  text-align: center;
  color: red; /* Optional: Customize the text color */
}
