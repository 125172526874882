

.submitButton {
  background-color: #3d9d3b;
  color: white;
  margin-bottom: 20px;
  margin-top: 40px;
  width: 50%;
}

.submitButton:hover {
  background-color: #1b5e20;
}


/* General Background */
.background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('./images/AceBldg.png') no-repeat center center fixed;
  background-size: cover;
  padding: 20px; /* Add padding for smaller screens */
  box-sizing: border-box; /* Ensure padding is included in the total width/height */
}

/* Main Container */
.container {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 600px; /* Set a maximum width for large screens */
  box-sizing: border-box; /* Ensure padding doesn't exceed width */
}

/* Logo */
.logo {
  width: 120px;
  margin-bottom: 20px;
}

/* Title */
.title {
  font-size: 24px;
  color: #28561f;
  font-weight: bold;
  margin-bottom: 15px;
}

.highlight {
  background: linear-gradient(to right, #f70301, #8b2f11, #28561f);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: 16px;
  color: gray;
  margin-bottom: 30px;
}

/* TextField Adjustments */
.MuiTextField-root {
  margin-bottom: 20px;
}

/* Buttons */
.MuiButton-root {
  height: 50px;
  width: 100%;
  max-width: 200px; /* Set max width for better layout control */
}

/* Responsive Layout for Smaller Screens */
@media (max-width: 768px) {
  .container {
    padding: 20px; /* Reduce padding for smaller screens */
    max-width: 90%; /* Reduce width for smaller screens */
  }

  .logo {
    width: 100px; /* Reduce logo size */
  }

  .title {
    font-size: 20px; /* Reduce title font size */
  }

  .subtitle {
    font-size: 14px; /* Adjust subtitle font size */
  }

  .MuiButton-root {
    font-size: 14px; /* Adjust button font size */
    max-width: 150px; /* Reduce button width */
  }
}

/* Extra Small Screens */
@media (max-width: 576px) {
  .background {
    padding: 10px; /* Add extra padding for smaller screens */
  }

  .container {
    padding: 15px; /* Reduce container padding further */
    max-width: 100%; /* Allow full width for extra small screens */
  }

  .logo {
    width: 80px; /* Further reduce logo size */
  }

  .title {
    font-size: 18px; /* Further reduce title font size */
  }

  .subtitle {
    font-size: 12px; /* Adjust subtitle font size for smaller screens */
  }

  .MuiButton-root {
    font-size: 12px; /* Adjust button font size */
    height: 40px; /* Reduce button height */
    max-width: 120px; /* Further reduce button width */
  }
}
