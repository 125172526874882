
  .editButton {
    background-color: #4CAF50;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    float: right;
  }
  
  .editButton:hover {
    background-color: #45a049;
  }

  